import { extend } from 'flarum/extend';
import app from 'flarum/app';
import DiscussionList from 'flarum/components/DiscussionList';

export default function() {
    extend(DiscussionList.prototype, 'view', function(component) {
        const advertisement = app.forum.attribute('flagrow.ads.between-discuss');

        if (advertisement && component.children.length) {
            const start = 0;
            const between = 11;

            const discuss = component.children;
            if(discuss.length === 2){
                const sorular = discuss[0].children;
                sorular.forEach((post, i) => {
                    if (i >= start && (i - start) % between === 0 && i < sorular.length - 1) {
                        post.children.push(
                            m('li.data-id=25252525',
                                m('div.DiscussionListItem',
                                    m('div.betweendiscuss', m.trust(advertisement))
                                )
                            )
                        );
                    }
                });
            }

            if(discuss.length === 3){
                const sorular = discuss[1].children;
                sorular.forEach((post, i) => {
                    if (i >= start && (i - start) % between === 0 && i < sorular.length - 1) {
                        post.children.push(
                            m('li.data-id=25252525',
                                m('div.DiscussionListItem',
                                    m('div.betweendiscuss', m.trust(advertisement))
                                )
                            )
                        );
                    }
                });
            }
        }
    });
}
